<template>
  <div class="apply_firm_box">
    <div class="apply_tabs">
      <process ref="next_li" :name_list="name_list"></process>
    </div>
    <div class="apply_firm_li" v-if="pace === 1">
      <div class="apply_text">
        <protocol @change="agree" />
      </div>
    </div>
    <div class="apply_firm_li" v-else-if="pace === 2">
      <div class="basic_wrap">
        <basic-info @type="set_status" ref="basic" />
        <FoodPhoto v-if="!firm_status" />
        <yield-info v-if="firm_status" ref="yield" />
        <div class="norm_info_wrap" v-if="firm_status">
          <div class="norm_info_title">
            企业标准信息（选填）
            <el-tooltip placement="right">
              <div slot="content">
                取得食品生产许可证的企业均为生产型企业，
                <br />其余情况均视为非生产型企业。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="norm_info_list">
            <norm-info ref="norm"></norm-info>
          </div>
        </div>
        <div class="set_btn tac">
          <el-button type="primary" @click="next_info(0)" v-if="set_arr">
            下一步
            <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
          <div class="next_btn" v-else>
            <el-button type="primary" @click="next_info(1)">确认提交</el-button>
            <el-button @click="next_info(2)">
              <i class="el-icon-arrow-left el-icon--left"></i>
              返回修改
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="apply_firm_li" v-else-if="pace === 3">
      <div class="vanify_code">
        <venify-admin ref="venify" @agree="agree"></venify-admin>
      </div>
    </div>
    <div class="apply_firm_li" v-else-if="pace === 4">
      <div class="basic_wrap">
        <div class="icon">
          <i class="el-icon-success"></i>
        </div>
        <p class="tac text">您的企业信息已提交成功！</p>
        <div class="tac">
          <div class="hint">正在努力的为您审核中，请您耐心等待结果...</div>
          <router-link to="/company">
            <el-button type="primary">查看我的企业信息</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Process,
  VerifyAdmin,
  BasicInfo,
  YieldInfo,
  NormInfo,
  FoodPhoto,
} from "@/components/verify_firm";
import Protocol from "./submit_tag/components/protocol";
export default {
  name: "apply_firm",
  components: {
    Process,
    VerifyAdmin,
    BasicInfo,
    YieldInfo,
    NormInfo,
    FoodPhoto,
    Protocol,
  },

  data() {
    return {
      pace: 1,
      set_arr: true,
      name_list: {
        one: "同意协议",
        two: "上传企业资料",
        three: "确认企业资料",
        four: "提交成功",
      },
      firm_status: true,
      // 默认内容
    };
  },
  created() {
    let id = this.$route.query.id;
  },
  mounted() {
    this.pace = 1;
    this.agree(1);
  },
  methods: {
    agree(e, st) {
      if (!st) {
        this.pace = e;
      }
      this.$refs.next_li.nexts(e);
    },
    async next_info(e) {
      let id = this.$route.query.id;
      let that = this;
      // 基本信息
      let basic = this.$refs.basic.submitForm();
      // 证件照
      let img_arr = this.$store.state.common.firm_img;
      let yields = {};
      let norm = "";
      let norm_arr = [];
      if (!basic.state) {
        this.$message({
          message: "带有*号的都是必填项，请您填写完整 ！ ",
          type: "error",
        });
        return false;
      }
      if (!img_arr.yy) {
        this.$message({
          message: "请先上传您的营业执照！ ",
          type: "error",
        });
        return false;
      }
      if (this.firm_status) {
        // 许可信息
        yields = await this.$refs.yield.submitForm();
        // 企业标准信息 选填
        norm = this.$refs.norm.submitForm();
        if (!yields.name) {
          this.$message({
            message: "请输入您的食品生产许可证编号 ",
            type: "error",
          });
          return false;
        }
        if (!img_arr.fb) {
          this.$message({
            message: "请先上传您的食品生产许可证正本或副本扫描件",
            type: "error",
          });
          return false;
        }
        if (!img_arr.pz) {
          this.$message({
            message: "请先上传您的食品生产许可证中品种明细表",
            type: "error",
          });
          return false;
        }
        norm_arr = [];
        for (let [index, e] of norm.domains.entries()) {
          if (e.value && e.list) {
            // 如果都有值
            norm_arr.push({
              standardNumber: e.value,
              standardFile: e.list,
            });
          } else if (e.value || e.list) {
            // 如果其中一个有值
            if (!e.value && !e.list) {
              // 如果全部没值
            } else {
              this.$message.error(
                `您的第${
                  index + 1
                }项标准信息缺少一个值未完成，请您补充后在提交！`
              );
              return false;
            }
          }
        }
      } else {
        if (!img_arr.sp) {
          this.$message({
            message: "请上传您的食品经营许可证！",
            type: "error",
          });
          return false;
        }
      }
      // 获取所有信息
      const info = {
        companyType: basic.arr.region === "生产型企业" ? 0 : 1,
        companyName: basic.arr.firm_name || "",
        taxNo: basic.arr.itin || "",
        contactName: basic.arr.name || "",
        contactMobile: basic.arr.tell || "",
        businessLicense: img_arr.yy || "",
        productionLicense: {
          productionLicenseNumber: "",
          productionLicenseCopy: "",
          productionLicenseDetail: "",
          moreFiles: "",
        },
        enterpriseStandards: "",
        foodBusinessLicense: undefined,
      };
      if (this.firm_status) {
        info.productionLicense = {
          // productionLicenseOriginal: img_arr.zb || "",
          productionLicenseNumber: yields.name || "",
          productionLicenseCopy: img_arr.fb || "",
          productionLicenseDetail: img_arr.pz || "",
          moreFiles: yields.moreFiles,
        };
        info.enterpriseStandards = norm_arr || "";
      } else {
        info.foodBusinessLicense = img_arr.sp || "";
      }
      if (e === 0) {
        this.set_arr = false;
        this.$store.commit("set_apply");
        this.agree(3, 3);
      } else if (e === 1) {
        if (id === "1") {
          this.$api.companies_put(info).then((res) => {
            if (res.errcode === 0) {
              this.$message.success("企业认证信息再次提交成功，请等待审核！");
              this.$api.companies_get().then((res) => {
                if (res.errcode === 0) {
                  that.$store.commit("set_enterprise", res.data);
                  that.$store.commit("set_firm_list", "");
                  // that.$router.push("/company");
                  this.agree(4);
                  this.pace = 4;
                }
              });
            }
          });
        } else {
          // 提交验证企业信息
          this.$api.companies(info).then((res) => {
            if (res.errcode === 0) {
              this.$message.success("企业认证信息提交成功，请等待审核！");
              this.$api.companies_get().then((res) => {
                if (res.errcode === 0) {
                  that.$store.commit("set_enterprise", res.data);
                  that.$store.commit("set_firm_list", "");
                  that.$router.push("/company");
                }
              });
            }
          });
        }
      } else if (e === 2) {
        this.agree(2);
        this.set_arr = true;
        this.$store.commit("set_apply");
      } else {
        console.log("出错~！");
      }
    },
    set_status(e) {
      this.firm_status = e;
    },
  },
  beforeDestroy() {
    // this.$refs.venify.off();
  },
};
</script>

<style lang="scss" scoped>
.apply_firm_box {
  .apply_tabs {
    margin: auto;
  }
  .apply_firm_li {
    height: 80%;
    .set_btn {
      margin: 100px 0;
      .el-button {
        margin: 0 100px;
        font-weight: bold;
        color: #000;
        padding: 14px 44px;
        border-radius: 50px;
        text-align: center;
        i {
          font-weight: bold;
        }
      }
    }
    .vanify_code {
      width: 355px;
      margin: auto;
      text-align: center;
    }
    .norm_info_wrap {
      .norm_info_title {
        font-size: 16px;
        font-weight: bold;
        line-height: 50px;
      }
      .el-icon-question {
        color: #f2c811;
      }
    }
    .basic_wrap {
      margin: auto;
      margin-top: 50px;
      .el-button--primary {
        color: #000;
      }
      .icon {
        padding-top: 110px;
        text-align: center;
        font-size: 95px;
        color: #f2c811;
      }
      .text {
        line-height: 50px;
        font-size: 20px;
        font-weight: bold;
      }
      .hint {
        font-size: 14px;
        margin-bottom: 100px;
      }
    }
  }
  .apply_text {
    // margin: 0 350px;
    margin-top: 50px;
    height: 100%;
  }
  .basic_wrap {
    width: 800px;
    margin: auto;
    margin-top: 50px;
  }
}
</style>
